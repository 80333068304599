body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Helvetica;
  src: url(/static/media/HelveticaNeue.bb5671ed.ttf); }

@font-face {
  font-family: Helvetica;
  src: url(/static/media/HelveticaNeueBold.7f281199.ttf);
  font-weight: bold; }

::-webkit-scrollbar {
  width: 6px !important;
  background-color: inherit !important;
  border-right: solid 1px rgba(72, 78, 115, 0.1) !important; }
  ::-webkit-scrollbar:hover {
    background-color: rgba(72, 78, 115, 0.1) !important; }

::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  background-color: rgba(72, 78, 115, 0.1) !important;
  border-left: solid 1px rgba(72, 78, 115, 0.1) !important; }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(50, 54, 82, 0.5) !important; }

::-webkit-scrollbar-track {
  background-color: transparent !important; }

* {
  font-family: Helvetica;
  text-rendering: geometricPrecision !important; }
  * a:focus {
    outline: none !important; }

body {
  background-color: #484e73;
  overflow: hidden; }
  body .padder {
    display: inline-block;
    width: 16px; }
  body .padding {
    padding: 16px; }
    body .padding > .header:first-child:not(.ui) {
      margin-right: -16px;
      padding-right: 16px; }
    body .padding > .my-list {
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 24px !important; }
  body .flex-1 {
    flex: 1 1 !important; }
  body .attach-left {
    align-items: flex-start; }
  body .cards {
    margin-left: 2px !important;
    height: 150px;
    align-items: center;
    justify-content: center;
    color: #0d71bb !important; }
    body .cards div:first-child {
      font-size: 12px; }
    body .cards div:nth-child(2) {
      padding-top: 10px;
      font-size: 32px;
      font-weight: 900; }
  body .cardss {
    height: 150px;
    background-color: #0d71bb !important;
    color: #ffffff;
    align-items: center;
    justify-content: center; }
    body .cardss div:first-child {
      font-size: 12px; }
    body .cardss div:nth-child(2) {
      padding-top: 10px;
      font-size: 32px;
      font-weight: 900; }
  body .aside-agents {
    width: 530px;
    height: calc(100vh - 128px) !important;
    display: block;
    position: relative;
    overflow: hidden;
    overflow-y: auto; }
    body .aside-agents .in-aside-agents {
      margin: 75px 40px 0 32px; }
  body .aside-saving-groups {
    width: 400px;
    max-height: calc(100vh - 128px) !important;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    position: relative; }
    body .aside-saving-groups .in-aside-savings-groups {
      margin: 75px 40px 0 32px !important; }
  body .aside-saving-groups-members {
    width: 400px;
    height: calc(100vh - 128px) !important;
    display: block;
    position: relative;
    overflow: hidden;
    overflow-y: auto; }
    body .aside-saving-groups-members .in-aside-savings-groups-members {
      margin: 75px 40px 0 32px; }
  body .profile-slight-detail {
    padding-bottom: 40px; }
  body .boxx-overview {
    display: flex;
    flex-direction: row;
    color: #393d58;
    text-align: center; }
    body .boxx-overview div:first-child {
      margin: 0 30px 0 15px; }
    body .boxx-overview div .boxx-value {
      font-size: 32px;
      font-weight: 900;
      margin: 20px 0px 20px 0px; }
    body .boxx-overview div .boxx-title {
      font-size: 16px;
      margin: 10px 0px 10px 0px; }
  body .boxxx-overview {
    display: flex;
    flex-direction: row;
    color: #393d58;
    margin-right: -10px; }
    body .boxxx-overview div:first-child {
      margin: 0 30px 0 15px; }
    body .boxxx-overview div .boxx-value {
      font-size: 32px;
      font-weight: 900;
      margin: 20px 0px 20px 0px; }
    body .boxxx-overview div .boxxx-title {
      font-size: 16px;
      margin: 10px 0px 10px 0px;
      font-weight: 600; }
  body .row-reverse {
    flex-direction: row-reverse !important; }
  body .column-reverse {
    flex-direction: column-reverse !important; }
  body .areaError {
    display: flex;
    flex-direction: row; }
    body .areaError span:first-child {
      flex: 1 1; }
    body .areaError span:last-child {
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase; }
  body .my-badge {
    display: inline-block;
    padding: 0px 6px;
    font-size: 9px;
    height: 11px;
    line-height: 11px;
    background-color: #fc5739;
    color: #ffffff;
    text-transform: uppercase;
    align-self: center;
    margin-left: 8px;
    border-radius: 6px; }
  body .some-sticky-table-stuff .my-data-table {
    overflow: unset !important; }
  body .header-mod {
    color: #393d58 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 17px !important; }
  body .steps-header {
    color: #393d58 !important; }
  body .my-badge-status-paid:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: auto 5px;
    background: #26b663; }
  body .my-badge-status-unpaid:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: auto 5px;
    background: #db2829; }
  body .my-badge-status-yellow:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: auto 5px;
    background: #ffc600; }
  body .pannels-scroll,
  body .pannels-row {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    overflow: auto; }
    body .pannels-scroll:not(.the-root),
    body .pannels-row:not(.the-root) {
      max-width: calc(100vw - 80px); }
  body .pannels-column {
    display: flex;
    flex: 1 1;
    flex-direction: column; }
    body .pannels-column .pannel-scroll-inside {
      height: calc(100vh - 128px);
      overflow: hidden;
      overflow-y: auto;
      width: 100%; }
      body .pannels-column .pannel-scroll-inside .my-data-table {
        overflow: unset !important; }
        body .pannels-column .pannel-scroll-inside .my-data-table .padding .header {
          position: -webkit-sticky !important;
          position: -moz-sticky !important;
          position: -ms-sticky !important;
          position: -o-sticky !important;
          position: sticky !important;
          top: 0 !important;
          background-color: #ffffff !important;
          z-index: 3 !important; }
        body .pannels-column .pannel-scroll-inside .my-data-table .padding .data-table {
          overflow: unset !important; }
          body .pannels-column .pannel-scroll-inside .my-data-table .padding .data-table table thead tr th {
            position: -webkit-sticky !important;
            position: -moz-sticky !important;
            position: -ms-sticky !important;
            position: -o-sticky !important;
            position: sticky !important;
            top: 50px !important;
            background-color: rgba(255, 255, 255, 0.95) !important; }
  body .pannel-scroll {
    display: block;
    flex: 1 1;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 32px;
    position: relative; }
    body .pannel-scroll:after {
      content: " ";
      display: block;
      position: relative;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: rgba(72, 78, 115, 0.1);
      left: 0;
      right: 0;
      margin: 0px auto; }
    body .pannel-scroll > .padding > .header:not(.ui) {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 3; }
  body .abrev-userr {
    display: flex;
    background-color: #fb4e7b !important;
    border-color: #fb4e7b !important;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    position: relative; }
    body .abrev-userr p {
      margin: auto !important;
      text-align: center;
      font-size: 48px;
      color: #ffffff; }
    body .abrev-userr span {
      vertical-align: bottom;
      position: absolute;
      bottom: 10%;
      right: 5%; }
      body .abrev-userr span .opacity-ic {
        opacity: 0.24 !important; }
  body .fsp-inside {
    margin: auto; }
  body .paid-amount-fsp {
    display: flex;
    margin: 10px auto 40px auto;
    height: 200px;
    width: 300px;
    box-shadow: 0 2px 3px 0 #26b663;
    border-radius: 5px;
    border: 1px solid #26b663;
    text-align: center; }
    body .paid-amount-fsp .paid-amount-fsp-text {
      font-size: 32px;
      color: #26b663;
      font-weight: 900;
      margin: 12px 0 12px 0; }
  body .unpaid-amount-fsp {
    display: flex;
    margin: auto;
    height: 200px;
    width: 300px;
    box-shadow: 0 2px 3px 0 #db2829;
    border-radius: 5px;
    border: 1px solid #db2829;
    text-align: center; }
    body .unpaid-amount-fsp .unpaid-amount-fsp-text {
      font-size: 32px;
      color: #db2829;
      font-weight: 900;
      margin: 12px 0 12px 0; }
  body .pointer {
    cursor: pointer; }
  body .rwf .ui.input input {
    padding-right: 32px; }
  body .rwf .ui.input:after {
    content: "RWF";
    position: absolute;
    display: block;
    width: 32px;
    right: 0px;
    font-size: 10px;
    font-weight: 600;
    height: 16px;
    line-height: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0; }
  body form .field label {
    font-weight: 400 !important; }
  body #app {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    flex-direction: row; }
    body #app #first-login {
      display: flex;
      position: fixed;
      z-index: 9999;
      width: 100vw;
      height: 100vh;
      top: 0px;
      left: 0px;
      background-color: #ffffff; }
      body #app #first-login > .pannel-scroll > .padding {
        max-width: 420px;
        position: relative;
        left: 0;
        right: 0;
        margin: 0px auto; }
    body #app.public-forms {
      align-items: center;
      justify-content: center; }
      body #app.public-forms div .ui.card {
        width: 400px;
        margin-top: 40px; }
      body #app.public-forms div a {
        color: #ffffff; }
        body #app.public-forms div a:hover {
          color: rgba(255, 255, 255, 0.5); }
    body #app .abrev {
      display: inline-block;
      height: 48px;
      width: 48px;
      background-color: #323652;
      border-radius: calc(48px/ 1.6);
      text-align: center;
      line-height: calc(48px - 4px);
      color: rgba(255, 255, 255, 0.75);
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 800;
      border: solid 2px #323652; }
      body #app .abrev.menu-item {
        background-color: rgba(255, 255, 255, 0.1);
        margin-top: 16px;
        position: relative; }
        body #app .abrev.menu-item img {
          height: calc(48px/ 2);
          width: calc(48px/ 2);
          display: inline-block;
          line-height: calc(48px/ 2); }
        body #app .abrev.menu-item:hover {
          background-color: #323652; }
        body #app .abrev.menu-item.active:after {
          content: "";
          display: block;
          height: 16px;
          width: 16px;
          position: absolute;
          background: #ffffff;
          border: solid #484e73 calc(16px / 4);
          border-radius: calc(16px / 1.5);
          top: 0;
          bottom: 0;
          margin: auto 0px;
          right: -18px; }
      body #app .abrev .dropdown.icon {
        display: none !important; }
    body #app #sidebar {
      width: 80px;
      background-color: #484e73;
      display: flex;
      flex-direction: column;
      padding: 16px; }
      body #app #sidebar .abrev {
        border-radius: 6px;
        border-color: transparent; }
    body #app #content {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      max-width: calc(100vw - 80px); }
      body #app #content #titlebar {
        height: 80px;
        background-color: #fbfbfb;
        display: flex;
        flex-direction: row;
        padding: 16px;
        border-bottom: solid 1px rgba(72, 78, 115, 0.1); }
        body #app #content #titlebar .home-link {
          display: block;
          height: 48px;
          padding-left: 16px;
          padding-right: 16px;
          margin-left: calc(16px * -1);
          margin-right: 16px;
          line-height: 48px;
          text-align: center;
          border-right: solid 1px rgba(72, 78, 115, 0.1); }
          body #app #content #titlebar .home-link:hover {
            background-color: rgba(72, 78, 115, 0.1); }
          body #app #content #titlebar .home-link:hover img, body #app #content #titlebar .home-link.active img {
            opacity: 1; }
          body #app #content #titlebar .home-link img {
            opacity: 0.35;
            display: inline-block;
            width: calc(48px* 0.65); }
        body #app #content #titlebar #top-selector > h1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 0px;
          line-height: 48px;
          color: rgba(50, 54, 82, 0.5);
          cursor: pointer; }
          body #app #content #titlebar #top-selector > h1 span {
            display: inline-block;
            max-width: 240px;
            line-height: 48px;
            height: 48px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.8em; }
          body #app #content #titlebar #top-selector > h1 .icon {
            margin-right: 0px;
            margin-left: calc(16px/ 2) !important;
            font-size: 24px;
            height: 24px;
            line-height: 24px; }
          body #app #content #titlebar #top-selector > h1:hover {
            color: #484e73; }
        body #app #content #titlebar #top-selector.selected h1 {
          color: #484e73; }
        body #app #content #titlebar > .ui.button {
          padding-top: 0px;
          padding-bottom: 0px;
          line-height: 48px; }
      body #app #content #pages {
        display: flex;
        flex-direction: row;
        flex: 1 1;
        background-color: #ffffff;
        height: calc(100vh - 80px);
        min-height: calc(100vh - 80px);
        max-height: calc(100vh - 80px); }
        body #app #content #pages form:not(.fluid) {
          max-width: 400px; }
        body #app #content #pages .sign-out-page {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1 1; }
        body #app #content #pages .toolbar {
          display: flex;
          flex-direction: row;
          height: calc(80px * 0.6); }
          body #app #content #pages .toolbar > * {
            height: calc(80px * 0.6);
            border-bottom: solid 2px rgba(72, 78, 115, 0.1); }
            body #app #content #pages .toolbar > *.active {
              font-weight: 600 !important;
              border-bottom-color: #484e73; }
          body #app #content #pages .toolbar .item {
            line-height: calc((80px * 0.6) - 2px);
            font-size: 16px;
            padding: 0px 16px;
            font-weight: 300;
            cursor: pointer;
            color: #323652; }
            body #app #content #pages .toolbar .item:hover {
              border-bottom-color: #484e73; }
        body #app #content #pages .header:not(.ui) {
          display: flex;
          height: calc(80px * 0.75);
          flex-direction: row;
          font-size: 14px;
          font-weight: 600;
          color: #323652;
          align-items: flex-end;
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: solid 1px rgba(72, 78, 115, 0.1); }
          body #app #content #pages .header:not(.ui) .title {
            font-size: 24px;
            line-height: 26px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 1; }
          body #app #content #pages .header:not(.ui) .back {
            line-height: 26px;
            height: 26px;
            padding-right: 32px;
            cursor: pointer; }
          body #app #content #pages .header:not(.ui) .backk {
            line-height: 26px;
            height: 26px;
            padding-right: 32px;
            margin-left: -16px;
            cursor: pointer; }
          body #app #content #pages .header:not(.ui) .ui.input,
          body #app #content #pages .header:not(.ui) .ui.button {
            height: 32px;
            min-height: 32px;
            max-height: 32px;
            margin-right: 0px;
            margin-left: 0px; }
          body #app #content #pages .header:not(.ui) .ui.button {
            padding-top: 0px !important;
            padding-bottom: 0px !important; }
            body #app #content #pages .header:not(.ui) .ui.button:not(.label),
            body #app #content #pages .header:not(.ui) .ui.button > *:not(.label) {
              height: 30px !important;
              line-height: 30px !important; }
            body #app #content #pages .header:not(.ui) .ui.button.label,
            body #app #content #pages .header:not(.ui) .ui.button > *.label {
              height: 32px !important;
              line-height: 32px !important; }
            body #app #content #pages .header:not(.ui) .ui.button.icon,
            body #app #content #pages .header:not(.ui) .ui.button > *.icon {
              padding-top: 2px; }
          body #app #content #pages .header:not(.ui) > .ui.button {
            margin-left: 1em; }
        body #app #content #pages .nameFilter,
        body #app #content #pages .typeFilter {
          display: inline-block;
          height: 18px;
          margin-left: 8px;
          line-height: 18px;
          font-size: 12px;
          font-weight: 600;
          border-radius: 4px;
          background-color: #57d9a3;
          color: #ffffff;
          padding: 0px 8px; }
          body #app #content #pages .nameFilter:not(.nameFilter),
          body #app #content #pages .typeFilter:not(.nameFilter) {
            background-color: #ff991f; }
        body #app #content #pages .sidebar {
          display: block;
          width: 200px;
          padding: 16px; }
          body #app #content #pages .sidebar .header:not(.ui) {
            border-bottom-color: transparent; }
            body #app #content #pages .sidebar .header:not(.ui) .title {
              font-size: 14px;
              line-height: 16px;
              text-transform: uppercase; }
          body #app #content #pages .sidebar a {
            display: block;
            margin-top: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(50, 54, 82, 0.5); }
            body #app #content #pages .sidebar a:hover {
              color: #484e73; }
            body #app #content #pages .sidebar a.active {
              color: #323652;
              font-weight: 600; }
        body #app #content #pages .my-list-custom {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: 40px; }
          body #app #content #pages .my-list-custom .item:nth-child(1) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(1) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(2) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(2) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(3) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(3) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(4) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(4) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(5) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(5) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(6) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(6) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(7) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(7) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(8) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(8) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(9) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(9) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(10) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(10) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(11) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(11) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(12) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(12) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(13) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(13) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(14) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(14) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(15) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(15) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(16) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(16) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(17) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(17) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(18) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(18) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(19) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(19) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(20) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(20) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(21) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(21) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(22) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(22) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(23) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(23) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(24) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(24) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(25) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(25) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(26) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(26) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(27) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(27) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(28) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(28) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(29) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(29) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(30) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(30) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(31) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(31) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(32) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(32) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(33) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(33) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(34) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(34) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(35) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(35) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(36) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(36) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(37) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(37) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(38) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(38) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(39) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(39) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(40) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(40) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(41) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(41) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(42) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(42) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(43) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(43) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(44) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(44) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(45) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(45) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(46) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(46) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(47) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(47) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(48) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(48) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(49) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(49) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(50) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(50) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(51) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(51) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(52) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(52) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(53) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(53) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(54) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(54) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(55) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(55) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(56) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(56) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(57) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(57) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(58) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(58) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(59) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(59) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(60) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(60) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(61) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(61) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(62) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(62) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(63) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(63) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(64) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(64) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(65) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(65) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(66) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(66) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(67) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(67) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(68) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(68) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(69) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(69) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(70) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(70) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(71) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(71) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(72) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(72) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(73) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(73) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(74) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(74) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(75) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(75) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(76) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(76) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(77) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(77) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(78) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(78) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(79) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(79) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(80) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(80) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(81) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(81) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(82) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(82) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(83) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(83) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(84) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(84) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(85) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(85) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(86) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(86) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(87) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(87) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(88) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(88) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(89) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(89) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(90) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(90) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(91) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(91) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(92) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(92) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(93) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(93) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(94) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(94) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(95) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(95) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(96) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(96) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(97) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(97) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(98) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(98) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(99) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(99) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(100) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(100) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(101) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(101) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(102) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(102) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(103) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(103) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(104) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(104) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(105) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(105) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(106) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(106) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(107) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(107) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(108) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(108) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(109) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(109) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(110) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(110) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(111) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(111) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(112) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(112) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(113) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(113) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(114) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(114) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(115) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(115) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(116) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(116) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(117) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(117) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(118) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(118) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(119) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(119) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(120) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(120) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(121) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(121) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(122) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(122) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(123) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(123) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(124) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(124) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(125) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(125) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(126) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(126) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(127) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(127) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(128) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(128) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(129) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(129) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(130) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(130) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(131) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(131) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(132) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(132) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(133) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(133) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(134) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(134) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(135) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(135) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(136) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(136) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(137) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(137) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(138) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(138) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(139) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(139) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(140) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(140) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(141) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(141) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(142) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(142) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(143) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(143) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(144) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(144) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(145) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(145) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(146) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(146) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(147) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(147) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(148) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(148) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(149) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(149) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(150) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(150) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(151) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(151) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(152) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(152) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(153) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(153) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(154) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(154) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(155) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(155) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(156) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(156) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(157) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(157) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(158) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(158) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(159) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(159) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(160) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(160) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(161) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(161) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(162) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(162) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(163) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(163) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(164) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(164) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(165) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(165) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(166) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(166) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(167) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(167) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(168) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(168) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(169) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(169) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(170) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(170) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(171) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(171) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(172) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(172) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(173) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(173) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(174) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(174) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(175) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(175) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
          body #app #content #pages .my-list-custom .item:nth-child(176) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(176) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(177) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(177) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(178) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(178) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(179) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(179) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(180) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(180) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(181) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(181) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(182) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(182) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(183) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(183) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(184) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(184) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(185) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(185) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(186) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(186) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
          body #app #content #pages .my-list-custom .item:nth-child(187) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(187) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(188) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(188) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(189) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(189) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(190) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(190) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(191) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(191) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(192) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(192) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(193) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(193) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(194) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(194) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(195) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(195) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(196) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(196) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(197) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(197) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(198) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(198) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
          body #app #content #pages .my-list-custom .item:nth-child(199) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(199) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
          body #app #content #pages .my-list-custom .item:nth-child(200) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list-custom .item:nth-child(200) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
        body #app #content #pages .my-list {
          display: block;
          padding: 8px 0px; }
          body #app #content #pages .my-list.activity .item .title {
            font-weight: 600 !important;
            font-size: 14px !important; }
          body #app #content #pages .my-list.activity .item .description {
            font-weight: 400 !important;
            font-size: 15px !important;
            color: #323652 !important; }
            body #app #content #pages .my-list.activity .item .description span.name,
            body #app #content #pages .my-list.activity .item .description span.amount {
              color: #4c9aff; }
          body #app #content #pages .my-list .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(1) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(1) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(2) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            tbody tr:nth-child(2) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(3) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(3) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(4) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            tbody tr:nth-child(4) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(5) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(5) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(6) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(6) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(7) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(7) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(8) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            tbody tr:nth-child(8) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(9) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(9) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(10) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(10) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(11) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(11) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(12) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(12) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(13) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(13) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(14) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(14) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(15) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(15) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(16) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(16) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(17) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(17) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(18) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(18) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(19) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(19) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(20) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(20) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(21) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(21) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(22) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(22) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(23) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(23) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(24) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(24) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(25) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(25) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(26) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(26) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(27) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(27) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(28) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(28) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(29) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(29) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(30) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(30) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(31) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            tbody tr:nth-child(31) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(32) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(32) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(33) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(33) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(34) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(34) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(35) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(35) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(36) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(36) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(37) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(37) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(38) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(38) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(39) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(39) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(40) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(40) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(41) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(41) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(42) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(42) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(43) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(43) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(44) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(44) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(45) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(45) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(46) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(46) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(47) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(47) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(48) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(48) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(49) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(49) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(50) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(50) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(51) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(51) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(52) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(52) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(53) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(53) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(54) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(54) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(55) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(55) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(56) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(56) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(57) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(57) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(58) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(58) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(59) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(59) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(60) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(60) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(61) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(61) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(62) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(62) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(63) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(63) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(64) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(64) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(65) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(65) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(66) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(66) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(67) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(67) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(68) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(68) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(69) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(69) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(70) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(70) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(71) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(71) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(72) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(72) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(73) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(73) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(74) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(74) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(75) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(75) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(76) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(76) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(77) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(77) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(78) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(78) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(79) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(79) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(80) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(80) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(81) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(81) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(82) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(82) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(83) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(83) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(84) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(84) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(85) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(85) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(86) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(86) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(87) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(87) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(88) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(88) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(89) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(89) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(90) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(90) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(91) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(91) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(92) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(92) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(93) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(93) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(94) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(94) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(95) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(95) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(96) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            tbody tr:nth-child(96) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(97) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(97) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(98) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(98) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(99) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(99) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(100) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(100) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(101) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(101) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(102) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(102) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(103) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(103) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(104) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(104) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(105) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(105) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(106) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(106) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(107) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(107) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(108) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(108) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(109) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(109) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(110) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(110) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(111) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(111) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(112) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(112) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(113) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(113) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(114) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(114) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(115) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            tbody tr:nth-child(115) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(116) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(116) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(117) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(117) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(118) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(118) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(119) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(119) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(120) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(120) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(121) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(121) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(122) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(122) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(123) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(123) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(124) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(124) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(125) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(125) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(126) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(126) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(127) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(127) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(128) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(128) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(129) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(129) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(130) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(130) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(131) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(131) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(132) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(132) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(133) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(133) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(134) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(134) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(135) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(135) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(136) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(136) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(137) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(137) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(138) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(138) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(139) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(139) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(140) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(140) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(141) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(141) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(142) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            tbody tr:nth-child(142) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(143) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(143) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(144) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(144) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(145) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(145) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(146) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(146) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(147) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(147) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(148) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(148) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(149) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(149) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(150) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(150) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(151) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(151) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(152) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(152) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(153) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(153) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(154) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(154) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(155) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(155) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(156) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(156) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(157) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(157) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(158) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(158) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(159) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            tbody tr:nth-child(159) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(160) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(160) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(161) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(161) .abrev {
              background-color: #a69674 !important;
              border-color: #a69674 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(162) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(162) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(163) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(163) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(164) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(164) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(165) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(165) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(166) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(166) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(167) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(167) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(168) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            tbody tr:nth-child(168) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(169) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(169) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(170) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(170) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(171) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(171) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(172) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(172) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(173) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(173) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(174) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            tbody tr:nth-child(174) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(175) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            tbody tr:nth-child(175) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(176) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(176) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(177) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(177) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(178) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(178) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(179) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(179) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(180) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(180) .abrev {
              background-color: #6564a5 !important;
              border-color: #6564a5 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(181) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(181) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(182) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            tbody tr:nth-child(182) .abrev {
              background-color: #a8414a !important;
              border-color: #a8414a !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(183) .abrev {
              background-color: #34688f !important;
              border-color: #34688f !important; }
            tbody tr:nth-child(183) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(184) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(184) .abrev {
              background-color: #a27c27 !important;
              border-color: #a27c27 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(185) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(185) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(186) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            tbody tr:nth-child(186) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(187) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            tbody tr:nth-child(187) .abrev {
              background-color: #0f83f2 !important;
              border-color: #0f83f2 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(188) .abrev {
              background-color: #f6cb06 !important;
              border-color: #f6cb06 !important; }
            tbody tr:nth-child(188) .abrev {
              background-color: #f38a31 !important;
              border-color: #f38a31 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(189) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(189) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(190) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(190) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(191) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(191) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(192) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            tbody tr:nth-child(192) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(193) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            tbody tr:nth-child(193) .abrev {
              background-color: #6a8a82 !important;
              border-color: #6a8a82 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(194) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(194) .abrev {
              background-color: #6a74a7 !important;
              border-color: #6a74a7 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(195) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            tbody tr:nth-child(195) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(196) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            tbody tr:nth-child(196) .abrev {
              background-color: #282725 !important;
              border-color: #282725 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(197) .abrev {
              background-color: #f35738 !important;
              border-color: #f35738 !important; }
            tbody tr:nth-child(197) .abrev {
              background-color: #f48605 !important;
              border-color: #f48605 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(198) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(198) .abrev {
              background-color: #573838 !important;
              border-color: #573838 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(199) .abrev {
              background-color: #c35d05 !important;
              border-color: #c35d05 !important; }
            tbody tr:nth-child(199) .abrev {
              background-color: #f0a007 !important;
              border-color: #f0a007 !important; }
            body #app #content #pages .my-list .item:not(.user-deactivated):nth-child(200) .abrev {
              background-color: #00aff1 !important;
              border-color: #00aff1 !important; }
            tbody tr:nth-child(200) .abrev {
              background-color: #1142bf !important;
              border-color: #1142bf !important; }
            body #app #content #pages .my-list .item:not(div):hover .abrev {
              color: white; }
            body #app #content #pages .my-list .item:not(div):hover .title,
            body #app #content #pages .my-list .item:not(div):hover .description,
            body #app #content #pages .my-list .item:not(div):hover .right {
              color: #323652; }
            body #app #content #pages .my-list .item .abrev {
              margin-right: 16px;
              height: 40px;
              width: 40px;
              line-height: 36px;
              font-weight: 400;
              font-size: 16px; }
            body #app #content #pages .my-list .item .body {
              display: flex;
              flex: 1 1;
              flex-direction: column;
              justify-content: center;
              min-height: calc(48px + 16px);
              border-bottom: solid 1px rgba(72, 78, 115, 0.1);
              padding: 8px 16px 8px 0px; }
              body #app #content #pages .my-list .item .body .title {
                font-size: 18px;
                font-weight: 200;
                line-height: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #323652; }
              body #app #content #pages .my-list .item .body .description {
                font-size: 12px;
                font-weight: 200;
                line-height: 14px;
                color: rgba(50, 54, 82, 0.5); }
            body #app #content #pages .my-list .item .right {
              display: flex;
              align-items: center;
              min-height: calc(48px + 16px);
              border-bottom: solid 1px rgba(72, 78, 115, 0.1);
              align-self: stretch;
              padding-right: 16px;
              font-size: 14px;
              font-weight: 200;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: rgba(50, 54, 82, 0.5); }
            body #app #content #pages .my-list .item:last-child .body,
            body #app #content #pages .my-list .item:last-child .right {
              border-bottom-color: transparent; }
            body #app #content #pages .my-list .item.user-deactivated {
              opacity: 0.35; }
        body #app #content #pages .data-table table {
          min-width: 100%;
          max-width: 100%; }
          body #app #content #pages .data-table table thead th,
          body #app #content #pages .data-table table tbody td {
            border: none;
            border-bottom: solid 2px rgba(72, 78, 115, 0.1);
            margin: 0px;
            padding: 8px 0px;
            text-align: left;
            padding-right: 16px; }
          body #app #content #pages .data-table table thead tr th:last-child,
          body #app #content #pages .data-table table tbody tr td:last-child {
            padding-right: 0px; }
          body #app #content #pages .data-table table thead th {
            padding-top: 16px;
            padding-bottom: 16px;
            line-height: 32px;
            font-weight: 800;
            color: #323652; }
            body #app #content #pages .data-table table thead th ._text_ {
              font-size: 16px; }
            body #app #content #pages .data-table table thead th ._prefix_ {
              font-size: 10px; }
            body #app #content #pages .data-table table thead th .filter {
              height: 26px;
              background-color: #ffffff;
              border: 1px solid rgba(72, 78, 115, 0.1);
              border-radius: 4px;
              padding: 0px 4px;
              flex-direction: row;
              display: flex;
              margin-top: 4px; }
              body #app #content #pages .data-table table thead th .filter select,
              body #app #content #pages .data-table table thead th .filter input {
                height: 24px;
                padding: 0px 8px;
                font-size: 12px;
                line-height: 22px;
                background-color: transparent;
                border: none;
                box-sizing: border-box;
                outline: none; }
              body #app #content #pages .data-table table thead th .filter input {
                flex: 1 1; }
            body #app #content #pages .data-table table thead th input.filter {
              padding: 0px 8px;
              font-size: 12px;
              line-height: 24px;
              outline: none;
              display: block;
              width: 100%; }
          body #app #content #pages .data-table table tbody td {
            border-bottom-width: 1px;
            white-space: nowrap;
            overflow: hidden;
            line-height: 32px;
            text-overflow: ellipsis;
            font-weight: 400;
            color: #323652; }
            body #app #content #pages .data-table table tbody td a {
              color: #323652; }
            body #app #content #pages .data-table table tbody td .name {
              display: flex;
              flex-direction: row; }
              body #app #content #pages .data-table table tbody td .name .abrev {
                height: 32px;
                width: 32px;
                margin-right: 16px;
                line-height: 28px;
                font-weight: 400;
                font-size: 16px; }
        body #app #content #pages .data-table .carets {
          padding-left: 8px; }
          body #app #content #pages .data-table .carets .sort {
            color: rgba(72, 78, 115, 0.1); }
        body #app #content #pages .value-card-custom {
          margin: 0 30px 30px 15px !important;
          min-width: 350px !important;
          max-width: 350px !important;
          color: #393d58 !important; }
        body #app #content #pages .value-card {
          color: #323652;
          display: flex;
          flex-direction: column;
          flex: 1 1; }
          body #app #content #pages .value-card > *:not(.card-inner) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          body #app #content #pages .value-card .titleHolderCustom {
            border-bottom: solid 1px rgba(72, 78, 115, 0.1);
            padding-bottom: 1em; }
          body #app #content #pages .value-card .titleHolder {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 100%; }
            body #app #content #pages .value-card .titleHolder .abrevContainer {
              display: block;
              margin-right: 16px; }
            body #app #content #pages .value-card .titleHolder .inner {
              display: flex;
              flex-direction: column;
              flex: 1 1;
              height: 48px; }
              body #app #content #pages .value-card .titleHolder .inner.hasAbrev {
                max-width: calc(100% - 64px); }
                body #app #content #pages .value-card .titleHolder .inner.hasAbrev.hasIcon {
                  max-width: calc(100% - 104px); }
              body #app #content #pages .value-card .titleHolder .inner.hasIcon {
                max-width: calc(100% - 40px); }
                body #app #content #pages .value-card .titleHolder .inner.hasIcon.hasAbrev {
                  max-width: calc(100% - 104px); }
            body #app #content #pages .value-card .titleHolder .iconContainer {
              margin-left: 16px; }
          body #app #content #pages .value-card .title,
          body #app #content #pages .value-card .date {
            max-width: 100%; }
          body #app #content #pages .value-card .title span {
            font-size: 14px;
            font-weight: 600; }
          body #app #content #pages .value-card .date span {
            font-size: 12px;
            font-weight: 300; }
          body #app #content #pages .value-card .title span,
          body #app #content #pages .value-card .date span,
          body #app #content #pages .value-card .titleHolder h2 span {
            display: inline-block;
            width: auto;
            max-width: 100%;
            height: 16px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          body #app #content #pages .value-card .titleHolder h2 span {
            height: 48px;
            line-height: 48px; }
          body #app #content #pages .value-card:not(.inverted) .date {
            color: rgba(50, 54, 82, 0.5); }
          body #app #content #pages .value-card.inverted.green {
            background-color: #57d9a3 !important; }
          body #app #content #pages .value-card.inverted.blue {
            background-color: #4c9aff !important; }
          body #app #content #pages .value-card.inverted.blues {
            background-color: #0d71bb !important; }
          body #app #content #pages .value-card.inverted.orange {
            background-color: #ff991f !important; }
          body #app #content #pages .value-card.inverted.red {
            background-color: #ff5630 !important; }
          body #app #content #pages .value-card.inverted.purple {
            background-color: #8766b6 !important; }
          body #app #content #pages .value-card.inverted.darkerBlue {
            background-color: #3d5fd8 !important; }
          body #app #content #pages .value-card.inverted.pink {
            background-color: #c0295d !important; }
          body #app #content #pages .value-card.inverted.red-mod {
            background-color: #fb4e7b !important; }
          body #app #content #pages .value-card .value {
            font-size: calc(100vw * 0.25 * 0.085);
            font-weight: 800;
            padding: 24px 0px; }
            body #app #content #pages .value-card .value .unit {
              font-size: 0.5em;
              font-weight: 600; }
          body #app #content #pages .value-card .rows {
            display: flex;
            flex-direction: column;
            flex: 1 1; }
            body #app #content #pages .value-card .rows .row {
              display: flex;
              flex-direction: row;
              max-width: 100%; }
              body #app #content #pages .value-card .rows .row .col {
                display: flex;
                flex: 1 1;
                flex-direction: column;
                max-width: 100%;
                justify-content: center;
                color: #323652;
                margin-top: 16px; }
                body #app #content #pages .value-card .rows .row .col:nth-child(2) {
                  padding-left: 8px; }
                body #app #content #pages .value-card .rows .row .col.noMargin {
                  margin-top: 0px; }
                body #app #content #pages .value-card .rows .row .col .label {
                  font-size: 13px;
                  font-weight: 600;
                  padding: 0px 0px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; }
                  body #app #content #pages .value-card .rows .row .col .label .badge {
                    display: inline-block;
                    padding: 0px 4px;
                    height: 13px;
                    line-height: 13px;
                    font-size: 10px;
                    font-weight: 600;
                    font-style: normal;
                    color: #ffffff;
                    border-radius: 7px;
                    margin-left: 4px;
                    background-color: #ff5630; }
                body #app #content #pages .value-card .rows .row .col.labelBadge .label span {
                  display: inline-block;
                  padding: 4px 16px;
                  font-size: 12px;
                  font-weight: 200;
                  color: #ffffff;
                  border-radius: 4px;
                  background-color: #57d9a3; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.green .label span {
                  background-color: #57d9a3 !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.blue .label span {
                  background-color: #4c9aff !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.blues .label span {
                  background-color: #0d71bb !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.orange .label span {
                  background-color: #ff991f !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.red .label span {
                  background-color: #ff5630 !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.purple .label span {
                  background-color: #8766b6 !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.darkerBlue .label span {
                  background-color: #3d5fd8 !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.pink .label span {
                  background-color: #c0295d !important; }
                body #app #content #pages .value-card .rows .row .col.labelBadge.red-mod .label span {
                  background-color: #fb4e7b !important; }
                body #app #content #pages .value-card .rows .row .col.paddingBoxx {
                  padding-top: 20px !important; }
                body #app #content #pages .value-card .rows .row .collabelNeigbour .label,
                body #app #content #pages .value-card .rows .row .col .value {
                  display: inline-block;
                  font-size: 15px;
                  font-weight: 200;
                  padding: 0px 0px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; }
                  body #app #content #pages .value-card .rows .row .collabelNeigbour .label:nth-child(2),
                  body #app #content #pages .value-card .rows .row .col .value:nth-child(2) {
                    padding-top: 2px; }
                body #app #content #pages .value-card .rows .row .col.labelFlat .label,
                body #app #content #pages .value-card .rows .row .col .value {
                  font-size: 15px;
                  font-weight: 200;
                  padding: 0px 0px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; }
                  body #app #content #pages .value-card .rows .row .col.labelFlat .label:nth-child(2),
                  body #app #content #pages .value-card .rows .row .col .value:nth-child(2) {
                    padding-top: 2px; }
                body #app #content #pages .value-card .rows .row .col.footer {
                  border-top: solid 1px rgba(72, 78, 115, 0.1);
                  padding-top: 1em; }
                body #app #content #pages .value-card .rows .row .col.right * {
                  text-align: right; }
              body #app #content #pages .value-card .rows .row .col:first-child:not(:last-child),
              body #app #content #pages .value-card .rows .row .col:last-child:not(:first-child) {
                max-width: 50%; }
          body #app #content #pages .value-card .chart {
            flex: 1 1;
            background-color: rgba(72, 78, 115, 0.05);
            display: flex;
            flex-direction: column;
            min-height: 80px;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: 6px; }
            body #app #content #pages .value-card .chart .recharts-wrapper,
            body #app #content #pages .value-card .chart .recharts-wrapper .recharts-surface {
              max-width: 100% !important; }
        body #app #content #pages .my-filter-panel,
        body #app #content #pages .my-filter-panel .pusher {
          display: flex;
          flex: 1 1;
          flex-direction: column; }
        body #app #content #pages .my-filter-panel > .ui.segment:first-child,
        body #app #content #pages .my-filter-panel > .credit-topup {
          border-top: none;
          border-bottom: none;
          width: 320px;
          padding: 0px 0px; }
          body #app #content #pages .my-filter-panel > .ui.segment:first-child .header:not(.ui) .title,
          body #app #content #pages .my-filter-panel > .credit-topup .header:not(.ui) .title {
            font-size: 24px;
            line-height: 26px;
            text-transform: none; }
      body #app #content #top-item-selector {
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 99; }
        body #app #content #top-item-selector .backdrop {
          display: absolute;
          position: fixed;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          opacity: 0;
          font-size: 1px; }
        body #app #content #top-item-selector .inner {
          display: absolute;
          position: fixed;
          top: 80px;
          left: 143.2px; }
          body #app #content #top-item-selector .inner .icon {
            display: block;
            overflow: hidden;
            font-size: 32px;
            width: 20px;
            color: #484e73;
            line-height: 10px !important;
            height: 10px !important;
            margin-left: 16px !important;
            margin-bottom: -2px !important;
            margin-top: -10px !important; }
            body #app #content #top-item-selector .inner .icon:before, body #app #content #top-item-selector .inner .icon:after {
              line-height: 10px !important;
              height: 10px !important; }
          body #app #content #top-item-selector .inner .scroll {
            display: flex;
            flex-direction: column;
            width: auto;
            max-width: 250px;
            min-width: 200px;
            background-color: #484e73;
            overflow: hidden;
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15); }
            body #app #content #top-item-selector .inner .scroll .search {
              padding: 16px;
              border-bottom: solid 1px rgba(255, 255, 255, 0.25); }
            body #app #content #top-item-selector .inner .scroll .items {
              display: flex;
              flex: 1 1;
              flex-direction: column;
              overflow: hidden;
              overflow-y: auto;
              max-height: 50vh; }
              body #app #content #top-item-selector .inner .scroll .items a {
                display: block;
                padding-right: 16px;
                line-height: 56px;
                margin-left: 16px;
                border-bottom: solid 1px rgba(255, 255, 255, 0.05);
                color: rgba(255, 255, 255, 0.5);
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 200; }
                body #app #content #top-item-selector .inner .scroll .items a:hover, body #app #content #top-item-selector .inner .scroll .items a.active {
                  color: #ffffff; }
                body #app #content #top-item-selector .inner .scroll .items a.active {
                  font-weight: 600; }
    body #app .wallet .pannel-scroll {
      padding-top: 32px; }
    body #app .wallet .wallet-balance {
      width: 320px; }
    body #app .content-loader {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      background-color: #ffffff;
      text-align: center; }
      body #app .content-loader .button {
        margin: 16px 0px; }
      body #app .content-loader.inverted {
        background-color: #484e73; }
      body #app .content-loader .ui.header {
        justify-content: center; }
  body .the-wallet > .padding:first-child {
    padding-bottom: 0px; }
    body .the-wallet > .padding:first-child > .some-sticky-stuff {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 4; }
    body .the-wallet > .padding:first-child .header:not(.ui) {
      margin-bottom: 0px !important;
      border-bottom: none !important; }
    body .the-wallet > .padding:first-child > .ui.grid:nth-child(2) .row .column .value-card .value {
      padding: 0px 0px 0px 0px !important; }
  body .my-data-table {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto; }
    body .my-data-table > .padding {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding-bottom: 0px !important; }
      body .my-data-table > .padding > .header:not(.ui) {
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 0;
        background-color: #ffffff;
        z-index: 3; }
      body .my-data-table > .padding .data-table {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        overflow: unset !important;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px; }
        body .my-data-table > .padding .data-table table tbody {
          min-width: 100%; }
          body .my-data-table > .padding .data-table table tbody tr {
            min-width: 100%; }
        body .my-data-table > .padding .data-table table thead {
          min-width: 100%; }
          body .my-data-table > .padding .data-table table thead tr {
            min-width: 100%; }
            body .my-data-table > .padding .data-table table thead tr th {
              position: -webkit-sticky;
              position: -moz-sticky;
              position: -ms-sticky;
              position: -o-sticky;
              position: sticky;
              top: 50px;
              background-color: rgba(255, 255, 255, 0.95); }
  body .my-select > .my-select-text {
    display: block;
    cursor: pointer;
    position: relative; }
    body .my-select > .my-select-text.placeholder {
      opacity: 0.65; }
    body .my-select > .my-select-text span:first-child {
      color: rgba(0, 0, 0, 0); }
    body .my-select > .my-select-text span:last-child {
      display: inline-block;
      height: 1em;
      line-height: 1em;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: absolute;
      left: 0px;
      top: 0px; }
  body .my-select > .loader {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 3;
    width: 36px; }
    body .my-select > .loader .loader {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      display: block; }

.equal.width.fields .field {
  max-width: 50% !important; }

.equal.width.fields .ui.button {
  height: 38px !important;
  align-self: flex-end !important;
  margin-left: 0.5em;
  margin-right: 0.5em; }

.my-select-modal .ui.checkbox label {
  padding-left: 32px !important; }

.my-select-modal .my-select-header {
  align-items: center;
  justify-content: center; }
  .my-select-modal .my-select-header label {
    font-size: 18px; }

.my-select-modal .content {
  max-height: calc(100vh - 240px) !important;
  overflow: hidden;
  overflow-y: auto; }
  .my-select-modal .content .items .item:not(:last-child) {
    padding-bottom: 16px; }

